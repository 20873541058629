body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edf2f6;
  color: #1f4352;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 767px) {
  .choose-campus {
    font-size: x-small;
  }
}

/* Style React Semantic UI Checkbox */
.ui.toggle.checkbox label {
  padding: 0;
  margin: 0;
}
